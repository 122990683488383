import React from 'react';
import ReactDOM from 'react-dom';
import './assets/App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Ping from 'views/ping';
import MTR from 'views/mtr';
import { ChakraProvider } from '@chakra-ui/react';
import { NotificationsProvider } from 'reapop'
import theme from './theme/theme';

const router = createBrowserRouter([
	{
		path: "/",
		children: [
			{
				index: true,
				element: <Ping />
			},
			{
				path: "ping",
				element: <Ping />,
			},
			{
				path: "mtr",
				element: <MTR />,
			},
		],
	},
]);

ReactDOM.render(
	<NotificationsProvider>
		<ChakraProvider theme={theme}>
			<React.StrictMode>
				<RouterProvider router={router} />
			</React.StrictMode>
		</ChakraProvider>
	</NotificationsProvider>,
	document.getElementById('root')
);
