/* eslint-disable */
import { Link as ReactRouterLink } from 'react-router-dom'
import { Box, Flex, Link as ChakraLink, useColorModeValue } from '@chakra-ui/react';

export default function Navbar() {
	const mainText = useColorModeValue('navy.700', 'white');
	const textColor = useColorModeValue('gray.600', 'white');

	return (
		<Box
			position='fixed'
			boxShadow='none'
			borderColor='transparent'
			filter='none'
			backdropFilter='blur(20px)'
			backgroundPosition='center'
			backgroundSize='cover'
			borderRadius='16px'
			borderWidth='1.5px'
			borderStyle='solid'
			transitionDelay='0s, 0s, 0s, 0s'
			transitionDuration=' 0.25s, 0.25s, 0.25s, 0s'
			transition-property='box-shadow, background-color, filter, border'
			transitionTimingFunction='linear, linear, linear, linear'
			alignItems={{ xl: 'center' }}
			display='flex'
			minH='75px'
			justifyContent={{ xl: 'center' }}
			lineHeight='25.6px'
			mx='auto'
			mt='0px'
			pb='8px'
			right='12px'
			px={{
				sm: '15px',
				md: '10px'
			}}
			ps={{
				xl: '12px'
			}}
			pt='8px'
			top={{ base: '12px', md: '16px', xl: '18px' }}
			w={{
				base: 'calc(100vw - 6%)',
				md: 'calc(100vw - 3%)'
			}}>
			<Flex
				w='100%'
				flexDirection='row'
				alignItems={{ xl: 'center' }}
				mb='0px'>
				<Box mb='0px'>
					<Box mb='8px' />
					<ChakraLink
						color={mainText}
						href='/'
						bg='inherit'
						borderRadius='inherit'
						fontWeight='bold'
						fontSize='34px'
						_hover={{ color: { mainText } }}
						_active={{
							bg: 'inherit',
							transform: 'none',
							borderColor: 'transparent'
						}}
						_focus={{
							boxShadow: 'none'
						}}>
						MTR.TN
					</ChakraLink>
				</Box>
				<Box ms='auto' w='unset'>
					<Flex
						w='auto'
						alignItems='center'
						flexDirection='row'
						flexWrap={'unset'}
						p='10px'>

						<Box>
							<ChakraLink
								padding='8px 12px'
								color={textColor}
								as={ReactRouterLink}
								to='/ping'
								bg='inherit'
								borderRadius='inherit'
								fontSize='20px'
								_hover={{ color: { textColor } }}
								_active={{
									bg: 'inherit',
									transform: 'none',
									borderColor: 'transparent'
								}}
								_focus={{
									boxShadow: 'none'
								}}>
								Ping
							</ChakraLink>
							<ChakraLink
								padding='8px 12px'
								color={textColor}
								as={ReactRouterLink}
								to='/mtr'
								bg='inherit'
								borderRadius='inherit'
								fontSize='20px'
								_hover={{ color: { textColor } }}
								_active={{
									bg: 'inherit',
									transform: 'none',
									borderColor: 'transparent'
								}}
								_focus={{
									boxShadow: 'none'
								}}>
								MTR
							</ChakraLink>
						</Box>
					</Flex>
				</Box>
			</Flex>
		</Box>
	);
}
