import { Portal, Box, Text, Link, Table, Tbody, Td, Th, Thead, Tr, Button, Flex, SimpleGrid, FormControl, FormLabel, Switch, Input, Select, useColorModeValue, Center } from '@chakra-ui/react';
import { useState, useEffect, useRef, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useForm } from 'react-hook-form'
import NotificationsSystem, { wyboTheme, useNotifications, setUpNotifications } from 'reapop'
import Card from 'components/Card';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

setUpNotifications({
	defaultProps: {
		position: 'top-right',
		dismissible: true
	}
})

export interface ipGeo {
	country: string,
	country_code: string,
	region: string,
	city: string,
	asn: number,
	asn_org: string,
	rdns: string,
}

interface ipInfo {
	[key: string]: ipGeo;
}

export interface serverStruct {
	name: string,
	protocol: number,
	i_s_p: string,
	provider: string,
	country: string,
	location: string,
	aff_link: string,
}

export interface serverMap {
	[key: string]: serverStruct;
}

interface MtrTable {
	key: React.Key;
	seq: string;
	ip: string;
	ip_geo: ipGeo;
	loss: number | string;
	sent: number | string;
	last: number | string;
	avg: number | string;
	best: number | string;
	worst: number | string;
	stdev: number | string;
	rttList: number[];
}

interface MtrCache {
	[key: string]: MtrItem
}

interface MtrItem {
	host: string;
	counter: number;
	rtt: number[];
}

const columnHelper = createColumnHelper<MtrTable>();

function dev(arr: number[]): number {
	const n = arr.length
	const mean = arr.reduce((a, b) => a + b, 0) / n
	return Math.sqrt(arr.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n)
}

function getProtocol(protocol: number): string {
	switch (protocol) {
		case 1:
			return '[v4]'
		case 2:
			return '[v6]'
		default:
			return ''
	}
}

export default function Ping() {
	let [searchParams, setSearchParams] = useSearchParams();
	const textColor = useColorModeValue("navy.700", "white");
	const buttonColor = useColorModeValue('purple.500', 'blue.500');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const inputColor = useColorModeValue('navy.700', 'white');
	const inputBorderColor = useColorModeValue('secondaryGray.100', 'rgba(135, 140, 189, 0.3)');
	const data = useRef({} as MtrCache);
	const [provider, setProvider] = useState('');
	const [ipLocation, setIpLocation] = useState({} as ipInfo);
	const [serverList, setServerList] = useState({} as serverMap);
	const [node, setNode] = useState(searchParams.get("n"));
	const [target, setTarget] = useState(searchParams.get("t"));
	const [protocol, setProtocol] = useState(searchParams.get("p") === null ? "0" : searchParams.get("p"));
	const [rd, setRd] = useState(searchParams.get("rd") === null ? "1" : searchParams.get("rd"));
	const [start, setStart] = useState(false);
	const [hasStart, setHasStart] = useState(false);
	const [change, setChange] = useState({});
	const [resolvedIP, setResolvedIP] = useState("N/A");
	const inProgress = useRef<{ [key: string]: boolean }>({});
	const { handleSubmit, register } = useForm()
	const { notifications, dismissNotification } = useNotifications()
	const { notify } = useNotifications()

	const columns = [
		columnHelper.accessor('seq', {
			id: 'seq',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					#
				</Text>
			),
			cell: (info: any) => (
				<Text color={textColor} fontSize='md' fontWeight='500' marginRight='5px'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('ip', {
			id: 'ip',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					IP
				</Text>
			),
			cell: (info) => {
				if (info.row.original.ip_geo.country === undefined && info.row.original.ip_geo.city === undefined) {
					if (info.row.original.ip_geo.asn_org === undefined) {
						return (
							<Box>
								<Text color={textColor} fontSize='md' fontWeight='500' mb={{ base: '0px', 'lg': '5px' }} >
									{info.getValue()}
								</Text>
								<Text fontSize='sm'>
									{info.row.original.ip_geo.rdns}
								</Text>
							</Box>
						)
					} else {
						return (
							<Box>
								<Text color={textColor} fontSize='md' fontWeight='500' mb={{ base: '0px', 'lg': '5px' }}>
									{info.getValue()}
									&nbsp;
									<Link fontSize='sm' fontWeight='300' href={`https://bgp.tools/as/${info.row.original.ip_geo.asn}`} isExternal target='_blank' rel='noreferrer'>
										(AS{info.row.original.ip_geo.asn} {info.row.original.ip_geo.asn_org})
									</Link>
								</Text>
								<Text fontSize='sm'>
									{info.row.original.ip_geo.rdns}
								</Text>
							</Box>
						)
					}
				} else {
					if (info.row.original.ip_geo.asn_org === undefined) {
						return (
							<Box>
								<Text color={textColor} fontSize='md' fontWeight='500' mb={{ base: '0px', 'lg': '5px' }} >
									{info.getValue()}
								</Text>
								<Text fontSize='sm' mb={{ base: '0px', 'lg': '5px' }} >
									{info.row.original.ip_geo.rdns}
								</Text>
								<Text fontSize='sm' fontWeight='300'>
									{info.row.original.ip_geo.city}, {info.row.original.ip_geo.region}, {info.row.original.ip_geo.country}
								</Text>
							</Box>
						)
					} else {
						return (
							<Box>
								<Text color={textColor} fontSize='md' fontWeight='500' mb={{ base: '0px', 'lg': '5px' }}>
									{info.getValue()}
									&nbsp;
									<Link fontSize='sm' fontWeight='300' href={`https://bgp.tools/as/${info.row.original.ip_geo.asn}`} isExternal target='_blank' rel='noreferrer'>
										(AS{info.row.original.ip_geo.asn} {info.row.original.ip_geo.asn_org})
									</Link>
								</Text>
								<Text fontSize='sm' mb={{ base: '0px', 'lg': '5px' }} >
									{info.row.original.ip_geo.rdns}
								</Text>
								<Text fontSize='sm' fontWeight='300'>
									{info.row.original.ip_geo.city}, {info.row.original.ip_geo.region}, {info.row.original.ip_geo.country}
								</Text>
							</Box>
						)
					}
				}
			}
		}),
		columnHelper.accessor('loss', {
			id: 'loss',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					LOSS
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('sent', {
			id: 'sent',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SENT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('last', {
			id: 'last',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					LAST
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('avg', {
			id: 'avg',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					AVG
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('best', {
			id: 'best',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					BEST
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('worst', {
			id: 'worst',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					WORST
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('stdev', {
			id: 'stdev',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					STDEV
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		})
	];

	function onSubmit(values: any) {
		setResolvedIP("N/A")
		setHasStart(true)

		const _n = values.n.trim()
		const _t = values.t.trim()
		const _p = values.p
		const _rd = values.rd ? "1" : "0"
		setNode(_n)
		setTarget(_t)
		setProtocol(_p)
		setRd(_rd)
		setStart(true)
		setSearchParams({
			n: _n,
			t: _t,
			p: _p,
			rd: _rd,
		});
	}

	const getIP = useCallback((ip: string, provider: string): ipGeo => {
		const result = {} as ipGeo
		if (ipLocation[ip + provider] !== undefined) {
			return ipLocation[ip + provider]
		}
		if (inProgress.current[ip + provider] || ip === "*") {
			return result
		}
		inProgress.current[ip + provider] = true
		fetch(`/api/ip?t=${ip}&p=${provider}`).then(r => r.json()).then(r => {
			setIpLocation((prev) => {
				const newIpLocation = { ...prev }
				if (r.hasOwnProperty("asn")) {
					result.asn = r.asn.number
					result.asn_org = r.asn.organization
				}
				if (r.hasOwnProperty("country")) {
					result.country = r.country.name
					result.country_code = r.country.code
				}
				if (r.hasOwnProperty("region")) {
					result.region = r.region
				}
				if (r.hasOwnProperty("city")) {
					result.city = r.city
				}
				if (r.hasOwnProperty("reverse")) {
					result.rdns = r.reverse
				}
				newIpLocation[ip + provider] = result
				return newIpLocation
			})
		})
		return result
	}, [ipLocation])

	let tableData = (change: object) => {
		let r: MtrTable[] = []
		for (const [key, value] of Object.entries<MtrItem>(data.current)) {
			const t = {
				key: key,
				seq: key,
				ip: value.host,
				ip_geo: getIP(value.host, provider),
				loss: value.counter - value.rtt.length,
				sent: value.counter,
				last: value.rtt[value.rtt.length - 1],
				avg: parseFloat((value.rtt.reduce((a, b) => a + b, 0) / value.rtt.length).toFixed(2)),
				best: Math.min.apply(null, value.rtt),
				worst: Math.max.apply(null, value.rtt),
				stdev: parseFloat(dev(value.rtt).toFixed(2))
			} as MtrTable
			if (t.ip === "*") {
				t.loss = "*"
				t.sent = "*"
				t.last = "*"
				t.avg = "*"
				t.best = "*"
				t.worst = "*"
				t.stdev = "*"
			}
			r.push(t)
			if (value.host === resolvedIP) {
				break
			}
		}
		return r
	}

	const nodeSelector = () => {
		const r = []
		for (const [key, value] of Object.entries<serverStruct>(serverList)) {
			r.push(
				<option key={key} value={key}>
					{value.location} {value.i_s_p} ({value.provider}) {getProtocol(value.protocol)}
				</option>
			)
		}
		return r
	}

	const table = useReactTable({
		data: tableData(change),
		columns,
		getCoreRowModel: getCoreRowModel(),
		debugTable: true
	});

	useEffect(() => {
		if (Object.keys(serverList).length <= 0) {
			fetch('/api/servers').then(r => r.json()).then(r => {
				setServerList(r)
			})
		}
		if (!start || target === "") {
			return
		}
		data.current = {} as MtrCache
		const sse = new EventSource(`/api/mtr?n=${node}&t=${target}&p=${protocol}&rd=${rd}`);
		sse.onmessage = (ev) => {
			let parsed = JSON.parse(ev.data)

			if (parsed.response.lookup !== undefined) {
				setResolvedIP(parsed.response.lookup.ip)
				return
			}

			if (parsed.response.error !== undefined) {
				setChange({})
				sse.close()
				setStart(false)
				notify('test finished', 'success', { dismissAfter: 1000 });
				return
			}

			if (parsed.pos === undefined) {
				parsed.pos = 0
				setChange({})
			}

			if (parsed.response.transmit !== undefined) {
				if (data.current[parsed.pos] === undefined) {
					data.current[parsed.pos] = {
						host: "*",
						counter: 1,
						rtt: [],
					}
				} else {
					data.current[parsed.pos].counter++
				}
			}

			if (parsed.response.host !== undefined) {
				if (data.current[parsed.pos] === undefined) {
					data.current[parsed.pos] = {
						host: parsed.response.host.ip,
						counter: 0,
						rtt: [],
					}
				} else {
					data.current[parsed.pos].host = parsed.response.host.ip
				}
			}

			if (parsed.response.ping !== undefined) {
				if (data.current[parsed.pos] === undefined) {
					data.current[parsed.pos] = {
						host: "*",
						counter: 1,
						rtt: [parsed.response.ping.rtt],
					}
				} else {
					data.current[parsed.pos].rtt.push(parsed.response.ping.rtt)
				}
			}
		};
		sse.onerror = () => {
			notify('server error or rate limit, try again later', 'error', { dismissAfter: 2000 });
			setStart(false)
		}
		return () => {
			sse.close()
			setStart(false)
		}
	}, [node, start, target, protocol, rd, serverList]);

	if (Object.keys(serverList).length <= 0) {
		return
	}

	return (
		<Box
			float='right'
			minHeight='94vh'
			height='95%'
			overflow='auto'
			position='relative'
			maxHeight='95%'
			w={{ base: '100%' }}
			maxWidth={{ base: '100%' }}
			transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
			transitionDuration='.2s, .2s, .35s'
			transitionProperty='top, bottom, width'
			transitionTimingFunction='linear, linear, ease'>
			<NotificationsSystem
				notifications={notifications}
				dismissNotification={(id) => dismissNotification(id)}
				theme={wyboTheme}
			/>
			<Portal>
				<Box>
					<Navbar />
				</Box>
			</Portal>

			<Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='94vh' pt='50px'>
				<Box pt={{ base: '40px', md: '60px' }}>
					<Card mb={{ base: '0px', '2xl': '20px' }}>
						<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							<FormControl>
								<SimpleGrid columns={{ base: 1, md: 2 }} gap='10px' >
									<SimpleGrid columns={{ base: 1, md: 2 }} gap='10px' >
										<Box>
											<FormLabel
												ms='4px'
												fontSize='sm'
												fontWeight='500'
												color={textColor}
												display='flex'>
												Target
											</FormLabel>
											<Input
												isRequired={true}
												fontSize='sm'
												bg='transparent'
												border='1px solid'
												borderRadius='16px'
												_placeholder={{ color: 'secondaryGray.600', fontWeight: '400' }}
												color={inputColor}
												borderColor={inputBorderColor}
												mb='12px'
												size='lg'
												variant='auth'
												defaultValue={target}
												disabled={start}
												{...register('t')}
											/>
										</Box>
										<Box>
											<FormLabel
												ms='4px'
												fontSize='sm'
												fontWeight='500'
												color={textColor}
												display='flex'>
												Probe
											</FormLabel>
											<Select
												isRequired={true}
												fontSize='sm'
												bg='transparent'
												border='1px solid'
												borderRadius='16px'
												color={inputColor}
												borderColor={inputBorderColor}
												mb='12px'
												size='lg'
												variant='auth'
												defaultValue={node}
												disabled={start}
												{...register('n')}
											>
												{nodeSelector()}
											</Select>
										</Box>
									</SimpleGrid>
									<SimpleGrid columns={{ base: 1, md: 3 }} gap='10px' >
										<Box>
											<FormLabel
												ms='4px'
												fontSize='sm'
												fontWeight='500'
												color={textColor}
												display='flex'>
												Protocol
											</FormLabel>
											<Select
												fontSize='sm'
												bg='transparent'
												border='1px solid'
												borderRadius='16px'
												color={inputColor}
												borderColor={inputBorderColor}
												mb='12px'
												size='lg'
												variant='auth'
												defaultValue={protocol}
												disabled={start}
												{...register('p')}
											>
												<option value="0">Auto</option>
												<option value="1">IPv4</option>
												<option value="2">IPv6</option>
											</Select>
										</Box>
										<Box>
											<FormLabel
												ms='4px'
												fontSize='sm'
												fontWeight='500'
												color={textColor}
												display='flex'>
												Provider
											</FormLabel>
											<Select
												fontSize='sm'
												bg='transparent'
												border='1px solid'
												borderRadius='16px'
												_placeholder={{ color: 'secondaryGray.600', fontWeight: '400' }}
												color={inputColor}
												borderColor={inputBorderColor}
												mb='12px'
												size='lg'
												id='balance'
												variant='auth'
												mt='5px'
												me='0px'
												defaultValue=''
												onChange={(event) => setProvider(event.target.value)}
												disabled={start}
											>
												<option value=''>IP2Location</option>
												<option value='m'>MaxMind</option>
												<option value='d'>DB-IP</option>
												<option value='c'>CZ88</option>
											</Select>
										</Box>
										<SimpleGrid columns={{ base: 2 }} gap='10px'>
											<Box mb='0px'>
												<FormLabel
													ms='4px'
													fontSize='sm'
													fontWeight='500'
													color={textColor}
													display='flex'>
													R-DNS
												</FormLabel>
												<Center w='40px' h={{ base: '20px', md: '48px' }}>
													<Switch
														defaultChecked={true}
														bg='transparent'
														borderRadius='16px'
														mb='12px'
														size='md'
														disabled={start}
														{...register('rd')}
													/>
												</Center>
											</Box>
											<Box w='unset'>
												<Box mb={{ base: '0px', md: '16px' }} />
												<Button
													h={{ base: '100%', md: '60%' }}
													width='100%'
													vertical-align='middle'
													fontSize='md'
													borderRadius='16px'
													bgColor={buttonColor}
													textColor='white'
													variant='brand'
													fontWeight='500'
													type='submit'
													isLoading={start}>
													Start
												</Button>
											</Box>
										</SimpleGrid>
									</SimpleGrid>
								</SimpleGrid>
							</FormControl>
						</form>
						<Text color={textColor} fontSize='lg' fontWeight='400' hidden={!hasStart}>
							{resolvedIP}
						</Text>
						<Box flexDirection='column' px='0px' overflowX={{ base: 'scroll', lg: 'hidden' }} pt='10px' hidden={!hasStart}>
							<Table variant='striped' color='gray.500' size='sm'>
								<Thead>
									{table.getHeaderGroups().map((headerGroup) => (
										<Tr key={headerGroup.id}>
											{headerGroup.headers.map((header) => {
												return (
													<Th
														key={header.id}
														colSpan={header.colSpan}
														pe='10px'
														borderColor={borderColor}
														cursor='pointer'>
														<Flex
															justifyContent='space-between'
															align='center'
															fontSize={{ sm: '10px', lg: '12px' }}
															color='gray.400'>
															{flexRender(header.column.columnDef.header, header.getContext())}{ }
														</Flex>
													</Th>
												);
											})}
										</Tr>
									))}
								</Thead>
								<Tbody>
									{table.getRowModel().rows.map((row) => {
										return (
											<Tr key={row.id}>
												{row.getVisibleCells().map((cell) => {
													return (
														<Td
															key={cell.id}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'>
															{flexRender(cell.column.columnDef.cell, cell.getContext())}
														</Td>
													);
												})}
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</Box>
					</Card>
				</Box>
			</Box>
			<Footer />
		</Box>
	);
}
