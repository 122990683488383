import { Portal, Box, Text, Link as ChakraLink, Table, Tbody, Td, Th, Thead, Tr, Button, Flex, SimpleGrid, FormControl, FormLabel, Switch, Input, Select, useColorModeValue, Center } from '@chakra-ui/react';
import { useState, useEffect, useRef, useCallback, ReactNode } from 'react';
import { Link as ReactRouterLink, useSearchParams } from 'react-router-dom';
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useForm } from 'react-hook-form'
import ReactCountryFlag from "react-country-flag";
import { PingResponse } from "../../../proto/mtrsb";
import Card from 'components/Card';
import Footer from 'components/Footer';
import Navbar from 'components/Navbar';

export interface ipGeo {
	country: string,
	country_code: string,
	region: string,
	city: string,
	asn: number,
	asn_org: string,
	rdns: string,
}

interface ipInfo {
	[key: string]: ipGeo;
}

export interface serverStruct {
	name: string,
	protocol: number,
	i_s_p: string,
	provider: string,
	country: string,
	location: string,
	aff_link: string,
}

export interface serverMap {
	[key: string]: serverStruct;
}

interface DataStruct {
	[key: string]: PingResponse[];
}

interface PingTable {
	key: React.Key;
	country: string;
	location: string;
	isp: string;
	protocol: number;
	provider: string;
	aff: string;
	ip: string;
	ip_geo: ipGeo;
	loss: number;
	sent: number;
	last: number;
	avg: number;
	best: number;
	worst: number;
	stdev: number;
	rttList: number[];
	node: string;
}

const columnHelper = createColumnHelper<PingTable>();

function dev(arr: number[]): number {
	const n = arr.length
	const mean = arr.reduce((a, b) => a + b, 0) / n
	return Math.sqrt(arr.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n)
}

function getProtocol(protocol: number): string {
	switch (protocol) {
		case 1:
			return '[v4]'
		case 2:
			return '[v6]'
		default:
			return ''
	}
}

export default function Ping() {
	let [searchParams, setSearchParams] = useSearchParams();
	const textColor = useColorModeValue("navy.700", "white");
	const buttonColor = useColorModeValue('purple.500', 'blue.500');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const inputColor = useColorModeValue('navy.700', 'white');
	const inputBorderColor = useColorModeValue('secondaryGray.100', 'rgba(135, 140, 189, 0.3)');
	const data = useRef({} as DataStruct);
	const [provider, setProvider] = useState('');
	const [ipLocation, setIpLocation] = useState({} as ipInfo);
	const [serverList, setServerList] = useState({} as serverMap);
	const [target, setTarget] = useState(searchParams.get("t"));
	const [protocol, setProtocol] = useState(searchParams.get("p") === null ? "0" : searchParams.get("p"));
	const [rd, setRd] = useState(searchParams.get("rd") === null ? "1" : searchParams.get("rd"));
	const [start, setStart] = useState(false);
	const [hasStart, setHasStart] = useState(false);
	const [change, setChange] = useState({});
	const inProgress = useRef<{ [key: string]: boolean }>({});
	const { handleSubmit, register } = useForm()

	const columns = [
		columnHelper.accessor('location', {
			id: 'location',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					LOCATION
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<ReactCountryFlag title={info.row.original.country} countryCode={info.row.original.country} svg style={{
						width: '21px',
						height: '15px',
						marginRight: '5px',
					}} />
					<Text color={textColor} fontSize='md' fontWeight='500' marginRight='5px'>
						{info.getValue()}
					</Text>
					<Text fontSize='sm'>{getProtocol(info.row.original.protocol)}</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('provider', {
			id: 'provider',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					PROVIDER
				</Text>
			),
			cell: (info) => {
				if (info.row.original.aff !== "") {
					return (
						<Box>
							<Text color={textColor} fontSize='md' fontWeight='500' mb={{ base: '0px', 'lg': '5px' }}>
								<ChakraLink href={info.row.original.aff} isExternal target='_blank' rel='noreferrer'>
									{info.getValue()}
								</ChakraLink>
							</Text>
							<Text fontSize='sm'>
								{info.row.original.isp}
							</Text>
						</Box>
					)
				} else {
					return (
						<Box>
							<Text color={textColor} fontSize='md' fontWeight='500' mb={{ base: '0px', 'lg': '5px' }}>
								{info.getValue()}
							</Text>
							<Text fontSize='sm'>
								{info.row.original.isp}
							</Text>
						</Box>
					)
				}
			}
		}),
		columnHelper.accessor('ip', {
			id: 'ip',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					IP
				</Text>
			),
			cell: (info) => {
				if (info.row.original.ip_geo.country === undefined && info.row.original.ip_geo.city === undefined) {
					if (info.row.original.ip_geo.asn_org === undefined) {
						return (
							<ChakraLink color={textColor} fontSize='md' fontWeight='500' as={ReactRouterLink} to={`/mtr?n=${info.row.original.node}&t=${info.getValue()}`} title='MTR' target='_blank' rel='noreferrer'>
								{info.getValue()}
							</ChakraLink>
						)
					} else {
						return (
							<Text color={textColor} fontSize='sm' fontWeight='300'>
								<ChakraLink fontSize='md' fontWeight='500' as={ReactRouterLink} to={`/mtr?n=${info.row.original.node}&t=${info.getValue()}`} title='MTR' target='_blank' rel='noreferrer'>
									{info.getValue()}
								</ChakraLink>
								&nbsp;(
								<ChakraLink href={`https://bgp.tools/as/${info.row.original.ip_geo.asn}`} isExternal target='_blank' rel='noreferrer'>
									AS{info.row.original.ip_geo.asn} {info.row.original.ip_geo.asn_org}
								</ChakraLink>
								)
							</Text>
						)
					}
				} else {
					if (info.row.original.ip_geo.asn_org === undefined) {
						return (
							<Box>
								<Text color={textColor} fontSize='md' fontWeight='500' mb={{ base: '0px', 'lg': '5px' }} >
									<ChakraLink as={ReactRouterLink} to={`/mtr?n=${info.row.original.node}&t=${info.getValue()}`} title='MTR' target='_blank' rel='noreferrer'>
										{info.getValue()}
									</ChakraLink>
								</Text>
								<Text fontSize='sm'>
									{info.row.original.ip_geo.city}, {info.row.original.ip_geo.region}, {info.row.original.ip_geo.country}
								</Text>
							</Box>
						)
					} else {
						return (
							<Box>
								<Text color={textColor} fontSize='sm' fontWeight='300' mb={{ base: '0px', 'lg': '5px' }}>
									<ChakraLink fontSize='md' fontWeight='500' as={ReactRouterLink} to={`/mtr?n=${info.row.original.node}&t=${info.getValue()}`} title='MTR' target='_blank' rel='noreferrer'>
										{info.getValue()}
									</ChakraLink>
									&nbsp;(
									<ChakraLink href={`https://bgp.tools/as/${info.row.original.ip_geo.asn}`} isExternal target='_blank' rel='noreferrer'>
										AS{info.row.original.ip_geo.asn} {info.row.original.ip_geo.asn_org}
									</ChakraLink>
									)
								</Text>
								<Text fontSize='sm'>
									{info.row.original.ip_geo.city}, {info.row.original.ip_geo.region}, {info.row.original.ip_geo.country}
								</Text>
							</Box>
						)
					}
				}
			}
		}),
		columnHelper.accessor('loss', {
			id: 'loss',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					LOSS
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('sent', {
			id: 'sent',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					SENT
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('last', {
			id: 'last',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					LAST
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('avg', {
			id: 'avg',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					AVG
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('best', {
			id: 'best',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					BEST
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('worst', {
			id: 'worst',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					WORST
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('stdev', {
			id: 'stdev',
			header: () => (
				<Text
					justifyContent='space-between'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					STDEV
				</Text>
			),
			cell: (info) => (
				<Text color={textColor} fontSize='md' fontWeight='500'>
					{info.getValue()}
				</Text>
			)
		}),
		columnHelper.accessor('rttList', {
			id: 'graph',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '12px' }}
					color='gray.400'>
					CHART
				</Text>
			),
			cell: (info) => {
				const lines = [] as ReactNode[]
				const max = Math.max.apply(null, info.getValue())
				let idx = -0.5
				info.getValue().forEach((x) => {
					idx += 1
					if (x === -1) {
						lines.push(<line x1={idx} y1="10" x2={idx} y2="0" style={{ stroke: "#f56666" }} />)
						return
					}
					lines.push(<line x1={idx} y1="10" x2={idx} y2={10 - x / max * 10} style={{ stroke: "#48bb77" }} />)
				})
				return <>
					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10" height="20">
						{lines}
					</svg>
				</>
			}
		})
	];

	function onSubmit(values: any) {
		setHasStart(true)
		const _t = values.t.trim()
		const _p = values.p
		const _rd = values.rd ? "1" : "0"
		setTarget(_t)
		setProtocol(_p)
		setRd(_rd)
		setStart(true)
		setSearchParams({
			t: _t,
			p: _p,
			rd: _rd,
		});
	}

	const getIP = useCallback((ip: string, provider: string): ipGeo => {
		const result = {} as ipGeo
		if (ipLocation[ip + provider] !== undefined) {
			return ipLocation[ip + provider]
		}
		if (inProgress.current[ip + provider] || ip === "*") {
			return result
		}
		inProgress.current[ip + provider] = true
		fetch(`/api/ip?t=${ip}&p=${provider}`).then(r => r.json()).then(r => {
			setIpLocation((prev) => {
				const newIpLocation = { ...prev }
				if (r.hasOwnProperty("asn")) {
					result.asn = r.asn.number
					result.asn_org = r.asn.organization
				}
				if (r.hasOwnProperty("country")) {
					result.country = r.country.name
					result.country_code = r.country.code
				}
				if (r.hasOwnProperty("region")) {
					result.region = r.region
				}
				if (r.hasOwnProperty("city")) {
					result.city = r.city
				}
				if (r.hasOwnProperty("reverse")) {
					result.rdns = r.reverse
				}
				newIpLocation[ip + provider] = result
				return newIpLocation
			})
		})
		return result
	}, [ipLocation])

	let tableData = (change: object) => {
		let r: PingTable[] = []
		for (const [key, value] of Object.entries<PingResponse[]>(data.current)) {
			if (value.length === 1) {
				continue
			}
			const rttList = value.filter((x) => x.reply !== undefined).map((x) => x.reply!.rtt!)
			const ip = value.filter((x) => x.lookup !== undefined).at(0)?.lookup!.ip!
			const graph = [] as number[]
			value.forEach((x) => {
				if (x.reply !== undefined) {
					graph.push(x.reply.rtt)
					return
				}
				if (x.timeout !== undefined) {
					graph.push(-1)
					return;
				}
			})
			r.push({
				key: key + value.length,
				country: serverList[key].country,
				location: serverList[key].location,
				isp: serverList[key].i_s_p,
				protocol: serverList[key].protocol,
				provider: serverList[key].provider,
				aff: serverList[key].aff_link,
				ip: ip,
				ip_geo: getIP(ip, provider),
				loss: value.filter((x) => x.timeout !== undefined).length,
				sent: value.filter((x) => x.reply !== undefined || x.timeout !== undefined).length,
				last: rttList.at(-1) ?? -1,
				avg: parseFloat((rttList.reduce((a, b) => a + b, 0) / rttList.length).toFixed(2)),
				best: Math.min.apply(null, rttList),
				worst: Math.max.apply(null, rttList),
				stdev: parseFloat(dev(rttList).toFixed(2)),
				rttList: graph,
				node: key,
			})
		}
		return r
	}

	const table = useReactTable({
		data: tableData(change),
		columns,
		getCoreRowModel: getCoreRowModel(),
		debugTable: true
	});

	useEffect(() => {
		if (Object.keys(serverList).length <= 0) {
			fetch('/api/servers').then(r => r.json()).then(r => {
				setServerList(r)
			})
		}
		if (!start || target === "") {
			return
		}
		data.current = {} as DataStruct
		const sse = new EventSource(`/api/ping?t=${target}&p=${protocol}&rd=${rd}`);
		sse.onmessage = (ev) => {
			let parsed = JSON.parse(ev.data)

			if (parsed.data.reply !== undefined && (parsed.data.reply.seq % 5 === 0)){
				setChange({})
			}

			let nodeName = parsed.node
			if (data.current[nodeName] === undefined) {
				data.current[nodeName] = [parsed.data]
			} else {
				data.current[nodeName].push(parsed.data)
			}
		};
		sse.onerror = (e) => {
			setStart(false)
		}
		return () => {
			sse.close()
			setStart(false)
		}
	}, [start, target, protocol, rd, serverList]);

	if (Object.keys(serverList).length <= 0) {
		return
	}

	return (
		<Box
			float='right'
			minHeight='94vh'
			height='95%'
			overflow='auto'
			position='relative'
			maxHeight='95%'
			w={{ base: '100%' }}
			maxWidth={{ base: '100%' }}
			transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
			transitionDuration='.2s, .2s, .35s'
			transitionProperty='top, bottom, width'
			transitionTimingFunction='linear, linear, ease'>
			<Portal>
				<Box>
					<Navbar />
				</Box>
			</Portal>

			<Box mx='auto' p={{ base: '20px', md: '30px' }} pe='20px' minH='94vh' pt='50px'>
				<Box pt={{ base: '40px', md: '60px' }}>
					<Card mb={{ base: '0px', '2xl': '20px' }}>
						<form onSubmit={handleSubmit(onSubmit)} autoComplete='off'>
							<FormControl>
								<SimpleGrid columns={{ base: 1, md: 2 }} gap='10px' >
									<Box>
										<FormLabel
											ms='4px'
											fontSize='sm'
											fontWeight='500'
											color={textColor}
											display='flex'>
											Target
										</FormLabel>
										<Input
											isRequired={true}
											fontSize='sm'
											bg='transparent'
											border='1px solid'
											borderRadius='16px'
											_placeholder={{ color: 'secondaryGray.600', fontWeight: '400' }}
											color={inputColor}
											borderColor={inputBorderColor}
											mb='12px'
											size='lg'
											variant='auth'
											defaultValue={target}
											disabled={start}
											{...register('t')}
										/>
									</Box>
									<SimpleGrid columns={{ base: 1, md: 3 }} gap='10px' >
										<Box>
											<FormLabel
												ms='4px'
												fontSize='sm'
												fontWeight='500'
												color={textColor}
												display='flex'>
												Protocol
											</FormLabel>
											<Select
												fontSize='sm'
												bg='transparent'
												border='1px solid'
												borderRadius='16px'
												color={inputColor}
												borderColor={inputBorderColor}
												mb='12px'
												size='lg'
												variant='auth'
												defaultValue={protocol}
												disabled={start}
												{...register('p')}
											>
												<option value="0">Auto</option>
												<option value="1">IPv4</option>
												<option value="2">IPv6</option>
											</Select>
										</Box>
										<Box>
											<FormLabel
												ms='4px'
												fontSize='sm'
												fontWeight='500'
												color={textColor}
												display='flex'>
												Provider
											</FormLabel>
											<Select
												fontSize='sm'
												bg='transparent'
												border='1px solid'
												borderRadius='16px'
												_placeholder={{ color: 'secondaryGray.600', fontWeight: '400' }}
												color={inputColor}
												borderColor={inputBorderColor}
												mb='12px'
												size='lg'
												id='balance'
												variant='auth'
												mt='5px'
												me='0px'
												defaultValue=''
												onChange={(event) => setProvider(event.target.value)}
												disabled={start}
											>
												<option value=''>IP2Location</option>
												<option value='m'>MaxMind</option>
												<option value='d'>DB-IP</option>
												<option value='c'>CZ88</option>
											</Select>
										</Box>
										<SimpleGrid columns={{ base: 2 }} gap='10px'>
											<Box mb='0px'>
												<FormLabel
													ms='4px'
													fontSize='sm'
													fontWeight='500'
													color={textColor}
													display='flex'>
													R-DNS
												</FormLabel>
												<Center w='40px' h={{ base: '20px', md: '48px' }}>
													<Switch
														defaultChecked={true}
														bg='transparent'
														borderRadius='16px'
														mb='12px'
														size='md'
														disabled={start}
														{...register('rd')}
													/>
												</Center>
											</Box>
											<Box w='unset'>
												<Box mb={{ base: '0px', md: '16px' }} />
												<Button
													h={{ base: '100%', md: '60%' }}
													width='100%'
													vertical-align='middle'
													fontSize='md'
													borderRadius='16px'
													bgColor={buttonColor}
													textColor='white'
													variant='brand'
													fontWeight='500'
													type='submit'
													isLoading={start}>
													Ping
												</Button>
											</Box>
										</SimpleGrid>
									</SimpleGrid>
								</SimpleGrid>
							</FormControl>
						</form>
						<Box flexDirection='column' px='0px' overflowX={{ base: 'scroll', lg: 'hidden' }} pt='10px' hidden={!hasStart}>
							<Table variant='striped' color='gray.500' size='sm'>
								<Thead>
									{table.getHeaderGroups().map((headerGroup) => (
										<Tr key={headerGroup.id}>
											{headerGroup.headers.map((header) => {
												return (
													<Th
														key={header.id}
														colSpan={header.colSpan}
														pe='10px'
														borderColor={borderColor}
														cursor='pointer'>
														<Flex
															justifyContent='space-between'
															align='center'
															fontSize={{ sm: '10px', lg: '12px' }}
															color='gray.400'>
															{flexRender(header.column.columnDef.header, header.getContext())}{ }
														</Flex>
													</Th>
												);
											})}
										</Tr>
									))}
								</Thead>
								<Tbody>
									{table.getRowModel().rows.map((row) => {
										return (
											<Tr key={row.id}>
												{row.getVisibleCells().map((cell) => {
													return (
														<Td
															key={cell.id}
															fontSize={{ sm: '14px' }}
															minW={{ sm: '150px', md: '200px', lg: 'auto' }}
															borderColor='transparent'>
															{flexRender(cell.column.columnDef.cell, cell.getContext())}
														</Td>
													);
												})}
											</Tr>
										);
									})}
								</Tbody>
							</Table>
						</Box>
					</Card>
				</Box>
			</Box>
			<Footer />
		</Box>
	);
}
