/*eslint-disable*/

import { Button, Flex, Icon, Text, useColorModeValue, useColorMode } from '@chakra-ui/react';
import { IoMdMoon, IoMdSunny } from 'react-icons/io';

export default function Footer() {
	const textColor = useColorModeValue('gray.400', 'white');
	const { colorMode, toggleColorMode } = useColorMode();

	return (
		<Flex position='relative' h='max-content'>
			<Flex
				position='relative'
				left={{ base: '0px', md: '35px' }}
				bottom='30px'
				display='flex'
				zIndex='3'
				flexDirection={{
					base: 'column',
					xl: 'row'
				}}
				alignItems={{
					base: 'center',
					xl: 'start'
				}}
				justifyContent='space-between'
				px={{ base: '30px', md: '50px' }}>
				<Text
					color={textColor}
					textAlign={{
						base: 'center',
						xl: 'start'
					}}
					mb='0px'>
					{' '}
					MTR.TN
				</Text>
			</Flex>
			<Button
				zIndex='99'
				position='fixed'
				variant='no-effects'
				left=''
				right='35px'
				bottom='30px'
				borderRadius='50px'
				onClick={toggleColorMode}
				display='flex'
				p='0px'
				alignItems='center'
				justifyContent='center'>
				<Icon h='24px' w='24px' color={textColor} as={colorMode === 'light' ? IoMdMoon : IoMdSunny} />
			</Button>
		</Flex>
	);
}
